import { useRef, useEffect } from 'react'
import styles from './Scroller.module.css'


const Scroller = ({ children, scrollWidth, scrollHeight, getScrollerElement }) => {
    const refContainer = useRef();

    let pos = { top:0, left:0, x:0, y:0 }

    // scroll to center on load
    useEffect(() => {        
        const w = (scrollWidth / 2) - (refContainer.current.offsetWidth / 2)
        refContainer.current.scrollTo(w, 0)
        getScrollerElement()?.addEventListener('mousedown', mouseDownHandler)
    }, [])

    const mouseDownHandler = (e) => {
        e.preventDefault();
        pos = {
            left: refContainer.current.scrollLeft,
            top: refContainer.current.scrollTop,
            x: e.clientX,
            y: e.clientY,
        };

        refContainer.current.style.cursor = 'grabbing'

        refContainer.current.addEventListener("mousemove", mouseMoveHandler);
        refContainer.current.addEventListener("mouseup", mouseUpHandler);
        refContainer.current.addEventListener("mouseout", mouseUpHandler);
    };

    const mouseMoveHandler = (e) => {
        e.preventDefault();
        refContainer.current.scrollTop = pos.top - (e.clientY - pos.y);
        refContainer.current.scrollLeft = pos.left - (e.clientX - pos.x);
    };

    const mouseUpHandler = (e) => {
        e.preventDefault();
        refContainer.current.removeEventListener("mousemove", mouseMoveHandler);
        refContainer.current.removeEventListener("mouseup", mouseUpHandler);
        refContainer.current.removeEventListener("mouseout", mouseUpHandler);
        
        refContainer.current.style.cursor = 'grab'
    };

    return (
        <div className='flex items-center justify-center h-screen overflow-hidden' style={{backgroundColor:'#2a72c0'}}>
            <div ref={refContainer} className={`${styles.scroller} relative overflow-x-scroll select-none`}>
                { children }
                {/* <div className='absolute top-0 left-0 cursor-grab' style={{ width: scrollWidth+'px', height: scrollHeight+'px', zIndex:1 }} onMouseDown={mouseDownHandler}></div> */}
            </div>
        </div>
    )
}

export default Scroller