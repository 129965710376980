import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import styles from './VideoHotspot.module.css'

function VideoHotspot({ left, top, width, height, thumbnail, onClickHandler }) {
    return (
        <div className='absolute z-20 overflow-hidden cursor-pointer' style={{ left:left+'px', top:top+'px', width:width+'px', height:height+'px' }}>
            <img src={ thumbnail } width={width} height={height} alt="Video Thumbnail"/>
            <button className={`${styles.btnPlay} absolute top-0 left-0 text-2xl text-white transition-transform hover:scale-125`} style={{width:width+'px', height:height+'px'}} onClick={ onClickHandler }>
                <div className={styles.VideoHotspot}></div>
                <FontAwesomeIcon icon={ faPlay } className={`${styles.playIcon} px-4 py-3 border-4 rounded-full playIcon`}/>
            </button>
        </div>
    )
}

export default VideoHotspot