import { createPortal } from 'react-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

function Modal({children, open, onClose, bgColor}) {
    if (!open) return null;

    return createPortal(
        <>
            <div className={`fixed inset-0 z-50 grid bg-opacity-95`} style={{ backgroundColor:bgColor }}>
                <button className='absolute px-4 py-2 mt-2 text-xl text-white transition-colors border-2 border-white rounded-full select-none hover:border-gray-400 hover:bg-white hover:text-gray-400 right-3 top-1' onClick={ onClose }>
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
                <div className='m-auto'>{ children }</div>
            </div>
        </>
        , document.getElementById('portal-modal')
    )
}

export default Modal