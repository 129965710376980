import { useState, useEffect, useRef } from 'react'
import Scroller from '../../components/Scroller'
import Modal from '../../components/Modal'
import VideoHotspot from '../../components/VideoHotspot'

// Images / Banners
import BgImage from '../../images/bg.jpg'
import LadyBanner from '../../images/lady.jpg'
import LyndavelBanner from '../../images/lyndavel.jpg'
import CopperBanner from '../../images/copper-iud.jpg'
import PpiudBanner from '../../images/ppiud.jpg'
import BloomBanner from '../../images/bloom.jpg'
import TrustPillBanner from '../../images/trustpill.png'
import DaphneBanner from '../../images/daphne.png'
import TrustCondomAds from '../../images/trust-condom-ads.jpg'

import VideoThumbnailImage from '../../images/dir-video-thumbnail-2.jpg'

// Popup Images
import LadyPopup from '../../images/popup/lady.jpg'
import LyndavelPopup from '../../images/popup/lyndavel.jpg'
import CopperPopup from '../../images/popup/copper-iud.jpg'
import BloomPopup from '../../images/popup/bloom.jpg'
import DaphnePopup from '../../images/popup/daphne.jpg'

import { CSSTransition } from 'react-transition-group'
import './Sample1.css'

import DirHandSign from '../../images/dir-hand-sign.png'
import TrustRH from '../../images/trust-rh.png'


const ImageHotspot = ({ origin, left, top, width, height, onClickHandler, src, alt }) => {
  return (
      <div className={`${origin ? 'origin-'+origin:''} absolute z-20 cursor-pointer transition-transform hover:transform hover:z-50 hover:scale-125`} 
        style={{ left:left+'px', top:top+'px', width:width+'px', height:height+'px' }}>
          <img className="relative z-50 select-none" src={src} width={width} height={height} alt={alt} onClick={onClickHandler} />
      </div>
  )
}

const ImageModal = ({ src, width, height, altText }) => {
  return (
    <div className="relative h-full bg-white shadow-2xl">
      <img className="object-contain max-h-screen p-2 select-none" src={src} height={'100%'} alt={altText} />
    </div>
  )
}

const VideoModal = ({ src, title }) => {
  return (
      <div className="m-auto bg-black shadow-2xl aspect-video" style={{ maxWidth:'100vw', width:'1366px', maxHeight:'100vh' }}>
          <iframe title={title} className="w-full max-w-full select-none aspect-video" src={src} allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameBorder={0}  allowFullScreen/>
      </div>
  )
}

const youtubeUrl = ({ videoId, start, end }) => `https://www.youtube.com/embed/${videoId}?start=${start}&end=${end}&autoplay=1&rel=0&controls=0&modestbranding=1&showinfo=0&origin=http://localhost:3000`

const modalData = [
  { id:1, image: <ImageModal key={'modal1'} width={800} height={582} src={LadyPopup} altText="Lady"/> },
  { id:2, image: <ImageModal key={'modal2'} width={800} height={582} src={LyndavelPopup} altText="Lyndavel"/> },
  { id:3, image: <ImageModal key={'modal3'} width={800} height={582} src={CopperPopup} altText="TRUST Copper T IUD"/> },
  { id:4, image: <VideoModal key={'modal4'} src={youtubeUrl({videoId:'56iK8HrT2Fo', start:0, end:0})}/> }, // PPIUD
  { id:5, image: <ImageModal key={'modal5'} width={800} height={582} src={BloomPopup} altText="TRUST Bloom"/> },
  { id:6, image: <VideoModal key={'modal6'} src={youtubeUrl({videoId:'kPBA33vuFfs', start:0, end:0})}/> }, // Ethical
  { id:7, image: <ImageModal key={'modal7'} width={339} height={800} src={DaphnePopup} altText="Daphne"/> },
  { id:8, image: <VideoModal key={'modal8'} src={youtubeUrl({videoId:'cC6Sc3A5WZU', start:0, end:0})}/> }, // Naked Truth
  { id:9, image: <VideoModal key={'modal9'} src={youtubeUrl({videoId:'bW8w8hT3CmY', start:0, end:0})}/> }, // OTC
]

const modalBgColor = ['#303030', '#fee252', '#ff9954', '#216cb0', '#216cb0', '#fe9456', '#f084b8', '#84d5f0','#883488','#216cb0']


const Booth = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState(0)
  const screenWidth = 1920
  const screenHeight = 969
  const [screenSize, setScreenSize] = useState({width:screenWidth, height:screenHeight})
  const [scale, setScale] = useState(1)
  const refScrollerElement = useRef()

  function updateScrollSize() {
    let s = window.innerHeight / screenHeight;
    let w = Math.round(screenWidth * s);
    setScreenSize({width:w, height: window.innerHeight})
    setScale(s)
  }

  useEffect(() => {
    console.log('Scroller Init')
    window.addEventListener('resize', () => {
      updateScrollSize();
    })
    updateScrollSize()
  }, [])
  
  useEffect(() => setIsOpen(selectedModal === 0 ? false : true), [selectedModal])

  useEffect(() => {
    if (isOpen === false) {
      setSelectedModal(0)
    }
  }, [isOpen])

  
  return (
    <>
      <Scroller scrollWidth={screenSize.width} scrollHeight={screenSize.height} getScrollerElement={() => refScrollerElement.current}>
        <div className="relative z-0" style={{width:screenSize.width+'px', height:screenSize.height+'px'}}>
          <img className="z-0 select-none" src={BgImage} 
            width={screenSize.width} height={screenSize.height} 
            alt="Background"/>
        </div>

        <div className='absolute z-30 origin-top-left' style={{left:0, top:0, width:screenWidth+'px', height:screenHeight+'px', transform:`scale(${scale},${scale})`}}>
          <ImageHotspot onClickHandler={() => setSelectedModal(1)} left={95} top={78} width={324} height={234} src={LadyBanner} alt="Lady" />
          <ImageHotspot onClickHandler={() => setSelectedModal(2)} left={447} top={78} width={324} height={234} src={LyndavelBanner} alt="Lyndavel" />
          <ImageHotspot onClickHandler={() => setSelectedModal(3)} left={799} top={78} width={324} height={234} src={CopperBanner} alt="TRUST Copper T IUD" />
          <ImageHotspot onClickHandler={() => setSelectedModal(4)} left={1151} top={78} width={324} height={234} src={PpiudBanner} alt="TRUST PPIUD" />
          <ImageHotspot onClickHandler={() => setSelectedModal(5)} left={1503} top={78} width={324} height={234} src={BloomBanner} alt="Bloom" />
          <ImageHotspot onClickHandler={() => setSelectedModal(6)} left={1408} top={440} width={172} height={418} src={TrustPillBanner} alt="Trust Pill" origin='right' />
          <ImageHotspot onClickHandler={() => setSelectedModal(7)} left={1631} top={460} width={172} height={419} src={DaphneBanner} alt="Daphne" origin='right' />
          <ImageHotspot onClickHandler={() => setSelectedModal(9)} left={434} top={521} width={212} height={284} src={TrustCondomAds} alt="TRUST CONDOMS" />
          <VideoHotspot onClickHandler={() => setSelectedModal(8)} left={97} top={473} width={272} height={164} thumbnail={VideoThumbnailImage} origin='left'/>
          <ImageHotspot onClickHandler={() => window.open('https://trust.ph', '_blank')} left={1021} top={730} width={94} height={93} src={TrustRH} alt="TRUST RHC"/>
          <ImageHotspot onClickHandler={() => window.open('https://doitright.ph', '_blank')} left={829} top={478} width={63} height={62} src={DirHandSign} alt="doitright hand sign" scale={'110'}/>
          <div ref={refScrollerElement} className='absolute inset-0 z-10'></div>
        </div>
      </Scroller>

      <CSSTransition in={isOpen} timeout={400} classNames="my-modal">
        <Modal open={isOpen} onClose={() => { setIsOpen(false); setSelectedModal(0)}} bgColor={ modalBgColor[selectedModal] }>
            {modalData.map(({ id, image }) => {
                if (id !== selectedModal) return null;
                return image;
            })}
        </Modal>
      </CSSTransition>
    </>
  )
}

export default Booth;



