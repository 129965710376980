import React from 'react'

function ErrorPage() {
  return (
    <div className='grid w-full h-screen text-center'>
      <div className='m-auto'>PAGE NOT FOUND!</div>
    </div>
  )
}

export default ErrorPage