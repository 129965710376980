import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
// import Home from './pages/Home'
import Booth from './pages/Booth'
import Sample1 from './pages/Sample1'
import ErrorPage from './pages/ErrorPage'

function App() {
  return (
    <div className=''>
    <Router>
      <Routes>
        <Route path="/" element={<Booth/>}/>
        <Route path="/booth" element={<Booth/>}/>
        <Route path="/booth2210" element={<Booth/>}/>
        <Route path="/sample1" element={<Sample1/>}/>
        <Route path="*" element={<ErrorPage/>}/>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
